import React from "react";
import queryString from "query-string";
import { StaticImage } from "gatsby-plugin-image";

// css
import * as styles from "./index.module.css";

const ThankYouPage = props => {
  const { appointmentTime } = queryString.parse(props.location.search);

  return (
    <div className={`bgDarkBlue ${styles.thankYouPage}`}>
      <div className="container twinContainer">
        <div className="row">
          <div className={`col-12`}>
            <div className={styles.thankYou__content}>
              <div className={styles.thankYou__content__img}>
                <StaticImage
                  src="../images/tick_green.png"
                  alt="icon"
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              <div className={styles.thankYou__content__cont}>
                <h3>Thank You</h3>
                {appointmentTime ? (
                  <div className="mt-3 mb-3">
                    <p>Appointment Date & Time:- {appointmentTime}</p>
                    <p className="mt-3">
                      We have received your request and will get back to you
                      soon.
                    </p>
                  </div>
                ) : (
                  <p>
                    We have received your request and will get back to you soon.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
